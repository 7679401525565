@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&family=Open+Sans:ital,wght@0,300;0,400;1,400&display=swap");
.heading-1 {
    font-family: Open Sans, sans-serif;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 2.4px
}

@media (max-width:767px) {
    .heading-1 {
        font-size: 18px;
        letter-spacing: normal
    }
}

.heading-2 {
    font-family: Nunito, sans-serif;
    font-size: 70px;
    font-weight: 300;
    letter-spacing: -2.8px
}

@media (max-width:767px) {
    .heading-2 {
        font-size: 28px;
        letter-spacing: normal
    }
}

.paragraph {
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px
}

@media (max-width:767px) {
    .paragraph {
        font-size: 14px;
        letter-spacing: normal
    }
}

.section-title-1 {
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 24px;
    font-weight: 400
}

.form-custom-input {
    min-height: 56px;
    height: 56px;
    border-radius: 28px;
    background-color: #fff;
    border: 0;
    padding: 0 20px;
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: italic
}

.form-custom-input.custom-select {
    background-image: url(../assets/images/select-caret.png)!important;
    background-size: 10px;
    background-position: 92%
}

.form-custom-input::-moz-placeholder {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: italic
}

.form-custom-input:-ms-input-placeholder {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: italic
}

.form-custom-input::-ms-input-placeholder {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: italic
}

.form-custom-input::placeholder {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: italic
}

.form-check-danger-text {
    color: #ff1300!important
}

.form-check-danger-text,
.form-check-success-text {
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 400
}

.form-check-success-text {
    color: #21c87a
}

input[type=checkbox]+label {
    display: block;
    cursor: pointer
}

input[type=checkbox] {
    display: none
}

input[type=checkbox]+label:before {
    content: "\2714";
    border: .1em solid rgba(0, 0, 0, .2);
    border-radius: .2em;
    display: inline-block;
    width: 16px;
    height: 16px;
    padding-left: .2em;
    padding-bottom: .3em;
    margin-right: 12px;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
    font-size: 11px
}

input[type=checkbox]+label:active:before {
    transform: scale(0)
}

input[type=checkbox]:checked+label:before {
    background-color: #3cb371;
    border-color: #3cb371;
    color: #fff
}

input[type=checkbox]:disabled+label:before {
    transform: scale(1);
    border-color: #aaa
}

input[type=checkbox]:checked:disabled+label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb
}

.btn {
    white-space: nowrap
}

.custom-primary-button {
    padding: 14px 24px;
    line-height: 1;
    border-radius: 5px;
    color: #fff;
    background-color: #4a9c63
}

.social-create-button {
    height: 65px;
    box-shadow: 0 7px 30px rgba(0, 0, 0, .08);
    border-radius: 7px;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 700
}

.create-account-with-facebbook-btn {
    background-color: #164aa9;
    color: #fff
}

.create-account-with-google-plus-btn {
    background-color: #df3527;
    color: #fff
}

.section-spacing-y {
    padding-top: 120px;
    padding-bottom: 120px
}

.section-title-spacing {
    padding-bottom: 66px
}

.section-title-size {
    max-width: 588px
}

.section-spacing-caption-title {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px
}

.section-main-title {
    color: #000;
    font-size: 40px
}

.section-action-line,
.section-main-title {
    font-family: Nunito, sans-serif;
    font-weight: 400
}

.section-action-line {
    color: #798795;
    font-size: 16px;
    text-decoration: underline;
    font-style: italic
}

.section-action-line__highlight {
    color: #6963ff;
    font-weight: 600
}

.section-action-line__highlight-icon {
    position: relative;
    top: -1px;
    left: 5px
}

.gray-divider {
    height: 1px;
    background-color: #000;
    opacity: .1
}

.header {
    max-height: 98px;
    position: fixed;
    left: 0;
    right: 0;
    border-bottom: 2px solid hsla(0, 0%, 100%, .1);
    z-index: 99;
    transition: all .3s ease;
    overflow: hidden
}

@media (max-width:991px) {
    .header {
        flex-wrap: wrap
    }
}

.header--active {
    background: linear-gradient(90deg, #0fa9d0 0, #3fbd52)
}

@media (max-width:991px) {
    .header--toggle {
        background: linear-gradient(90deg, #0fa9d0 0, #3fbd52);
        max-height: unset;
        overflow: auto;
        padding-bottom: 16px
    }
}

.header__hamburger {
    cursor: pointer;
    font-size: 24px
}

@media (max-width:991px) {
    .header__hamburger {
        order: 1
    }
}

.header__logo {
    margin-left: 16px
}

@media (max-width:991px) {
    .header__logo {
        order: 2
    }
}

.header__logo img {
    max-width: 156px;
    max-height: 96px
}

@media (min-width:1600px) {
    .header__logo {
        margin-left: auto
    }
}

@media (max-width:991px) {
    .header__menu-container {
        order: 4;
        flex: 1 1 100%
    }
}

.header__button {
    margin-right: 16px
}

@media (max-width:991px) {
    .header__button {
        order: 3
    }
}

@media (min-width:1600px) {
    .header__button {
        margin-right: auto
    }
}

.header .nav-link {
    color: #fff;
    font-size: 18px;
    font-family: Nunito, sans-serif;
    font-weight: 300;
    line-height: 1;
    padding-left: 17px!important;
    padding-right: 17px!important;
    white-space: nowrap
}

.header .nav-link.active {
    border-radius: 5px;
    background-color: #6963ff
}

.banner {
    min-height: 100vh;
    height: 100%;
    padding-top: 98px;
    background-image: linear-gradient(90deg, rgba(15, 169, 208, .7) 0, rgba(63, 189, 82, .75))
}

.banner-text-color {
    color: #fff
}

.banner__form {
    max-width: 972px;
    box-shadow: 0 0 14px 2px rgba(26, 130, 238, .24);
    border-radius: 32px
}

@media (min-width:768px) {
    .banner__form {
        height: 72px
    }
}

.banner__form--spacing {
    margin-top: 66px
}

.banner__form-input {
    flex: 1
}

@media (max-width:767px) {
    .banner__form-input {
        width: 100%
    }
}

@media (min-width:992px) {
    .banner__form-input--medium {
        max-width: 320px
    }
}

@media (min-width:992px) {
    .banner__form-input--narrow {
        max-width: 280px
    }
}

.banner__form-input-action-button {
    top: 0;
    right: 0;
    width: 90px;
    height: 56px;
    border-radius: 28px;
    background-color: #21c87a
}

.category--spacing-bottom {
    margin-bottom: -30px
}

.category .category-card {
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 30px;
    transition: border-color .3s ease
}

.category .category-card:hover {
    border-color: #21c87a
}

.category .category-card:hover .category-card-title {
    color: #21c87a
}

.category .category-card--spacing {
    padding: 30px 46px
}

.category .category-card-img-icon {
    margin-bottom: 13px
}

.category .category-card-title {
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 400;
    transition: color .3s ease
}

.category .category-card-description {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal
}

.our-classified-ads {
    background-color: #f5f9ff
}

.our-classified-ads__header {
    margin-bottom: 70px
}

.our-classified-ads__title-2 {
    margin-bottom: 51px
}

.our-classified-ads__category-dot {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #798795;
    opacity: .3
}

.our-classified-ads__category-title {
    color: #798795;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase
}

.our-classified-ads__category-item-container {
    padding: 0 28px
}

.our-classified-ads__category-item-row {
    margin: -28px
}

.our-classified-ads__category-item {
    margin: 0 28px
}

.our-classified-ads__card {
    margin-bottom: 50px
}

.our-classified-ads__card:hover .our-classified-ads__card-btn {
    color: #fff;
    background-color: #21c87a
}

.our-classified-ads__card-badge {
    width: 96px;
    height: 24px;
    border-radius: 4px;
    background-color: #f85c70;
    color: #fff;
    white-space: nowrap;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    left: 10px;
    top: 10px
}

.our-classified-ads__card-media {
    height: 220px;
    background-color: #ccc
}

.our-classified-ads__card-body {
    background-color: #fff;
    padding: 30px 20px
}

.our-classified-ads__card-title-1 {
    color: #21c87a;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 400
}

.our-classified-ads__card-title-2 {
    color: #010101;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 400
}

.our-classified-ads__card-description {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400
}

.our-classified-ads__card-price {
    width: 52px;
    height: 15px;
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 400
}

.our-classified-ads__card .fa-star-o--active {
    color: #ffb422
}

.our-classified-ads__card-btn {
    padding: 8px 15px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(121, 135, 149, .1);
    background-color: #fff;
    transition: all .3s ease
}

.top-place__title-2 {
    margin-bottom: 63px
}

.top-place__item {
    height: 270px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .3);
    margin-bottom: 30px;
    transition: all .3s ease
}

.top-place__item:hover {
    background: linear-gradient(90deg, rgba(15, 169, 208, .7) 0, rgba(63, 189, 82, .75))
}

.top-place__item-heading {
    color: #fff;
    font-family: Nunito, sans-serif;
    font-size: 24px;
    font-weight: 400;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0
}

.top-place__item--portrait {
    height: 569px
}

.top-place__section-action-line {
    margin-top: 54px
}

.company-highlight {
    background-color: #f5f9ff;
    background-image: url(../assets/images/company-highlight/company-highlight-bg.svg);
    background-size: 10%;
    background-repeat: no-repeat
}

.company-highlight__items-wrapper {
    margin-bottom: 70px
}

@media (min-width:1400px) {
    .company-highlight__items-wrapper {
        margin: 0 -25px
    }
}

.company-highlight__item {
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 70px
}

.company-highlight__item-name {
    margin-top: 16px;
    opacity: .8;
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 300
}

.company-highlight__divider {
    margin-bottom: 70px
}

.company-highlight__feature--fill-1 {
    color: #fb2602
}

.company-highlight__feature--fill-2 {
    color: #a639ee
}

.company-highlight__feature--fill-3 {
    color: #06bfd6
}

.company-highlight__feature--fill-4 {
    color: #c8a605
}

.company-highlight__feature-title-1 {
    height: 12px;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px
}

.company-highlight__feature-title-1 sup {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px
}

.company-highlight__feature-title-2 {
    color: #798795;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 0
}

.company-highlight__feature-icon {
    margin-right: 10px;
    width: 23px;
    height: 23px
}

.latest-news .latest-news__title-2 {
    margin-bottom: 70px
}

@media (max-width:1199px) {
    .latest-news__card {
        margin-bottom: 50px
    }
}

.latest-news__card-media {
    width: 100%;
    height: 250px;
    background-color: rgba(0, 0, 0, .5);
    background-color: #ccc
}

@media (min-width:1200px) {
    .latest-news__card-media {
        max-width: 370px
    }
}

.latest-news__card-body {
    box-shadow: 0 10px 30px rgba(0, 0, 0, .03)
}

.latest-news__card-body-content {
    padding: 30px 30px 50px
}

.latest-news__card-quote-box {
    padding: 18px;
    background-color: #f5f9ff
}

.latest-news__card-header-title {
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-top: 32px
}

.latest-news__card-header-author {
    color: #798795;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: auto;
    line-height: 1
}

.latest-news__card-date-comments {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400
}

.latest-news__card-date-comments--spacing {
    margin-left: 30px
}

.latest-news__card-post-title {
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 700
}

.latest-news__card-post-description {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #798795
}

.latest-news__card-btn {
    border-radius: 4px;
    border: 1px solid rgba(121, 135, 149, .1);
    background-color: #fff
}

.footer-container {
    background-color: #191a1a
}

.footer-follow-us {
    padding: 16px
}

@media (min-width:992px) {
    .footer-follow-us {
        padding: 0;
        height: 136px
    }
}

.footer-follow-us-title-1 {
    color: #fff;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 700;
    font-family: Nunito - Light;
    font-weight: 300
}

.footer-follow-us-title-2 {
    color: #f5f9ff;
    font-family: Nunito, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px
}

.footer-follow-us-description {
    color: #798795;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px
}

.footer-copy-right {
    background-color: #131313
}

@media (min-width:992px) {
    .footer-copy-right {
        height: 80px
    }
}

.footer-copy-right-text,
.footer-copy-right__link {
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400
}

.main-footer {
    padding-top: 70px;
    padding-bottom: 70px;
    border-top: 1px solid hsla(0, 0%, 100%, .1);
    color: #798795
}

.main-footer a {
    display: block;
    color: #798795;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 44px;
    word-break: break-word
}

.main-footer a:hover {
    color: #21c87a;
    text-decoration: none
}

.main-footer span {
    word-break: break-word;
    line-height: 44px
}

.main-footer__logo {
    max-height: 100px
}

.main-footer__heading {
    color: #fff;
    font-family: Nunito, sans-serif;
    font-size: 24px;
    font-weight: 400
}

@media (max-width:767px) {
    .main-footer__heading {
        margin-top: 24px
    }
}

.main-footer__heading-divider {
    width: 50px;
    height: 5px;
    border-radius: 3px;
    background-color: #fff;
    opacity: .1
}

.main-footer__icon-img {
    height: 24px;
    width: 24px
}

.main-footer__links-top-spacing {
    margin-top: 35px
}

.main-footer__links-top-spacing-xl {
    margin-top: 76px
}

.main-footer__communication-list {
    margin-bottom: 50px
}

.main-footer__communication-item {
    margin-left: 30px
}

.main-footer__communication-item a,
.main-footer__communication-item span {
    line-height: normal;
    font-size: 18px;
    font-weight: 400
}

.page-with-title-banner__title-section {
    height: 468px;
    padding-top: 98px;
    padding-bottom: 78px;
    background-image: linear-gradient(90deg, rgba(15, 169, 208, .7) 0, rgba(63, 189, 82, .75))
}

.page-with-title-banner__title-section .page-with-title-banner__heading-1 {
    color: #fff;
    font-family: Nunito, sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px
}

.page-with-title-banner__title-section .page-with-title-banner__heading-2 {
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #fff
}

.page-with-title-banner__pulled-form {
    margin-top: -78px;
    padding: 50px 0;
    background-color: #21c87a;
    border-radius: 6px
}

.page-form {
    margin-top: 120px
}

.page-form__title {
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -1.92px
}

.page-form .page-form__description {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #798795
}

.page-form .page-form__form {
    margin-top: 64px
}

.page-form .page-form__form label {
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 400
}

.page-form .page-form__form-action {
    margin-top: 64px;
    background-color: #21c87a;
    color: #fff;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 700
}

.page-form .page-form__custom-input,
.page-form .page-form__form-action {
    border: 0;
    height: 65px;
    box-shadow: 0 7px 30px rgba(0, 0, 0, .08);
    border-radius: 7px
}

.page-form .page-form__custom-input {
    padding: 0 30px;
    background-color: #fff
}

.page-form .page-form__footer-helper-text {
    color: #000;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 400
}

.brand-box-section-spacing {
    margin-bottom: -15px
}

.brand-box {
    width: 120px;
    height: 140px;
    box-shadow: 0 2px 30px rgba(0, 0, 0, .05);
    border-radius: 6px;
    background-color: #fff;
    margin: 0 15px 16px
}

.brand-box__title {
    opacity: .8;
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 300
}

.page-input-type-2 {
    flex: 1;
    border-radius: 6px;
    background-color: #fff
}

.page-input-type-2__action-button {
    cursor: pointer;
    top: 0;
    right: 0;
    width: 100px;
    height: 56px;
    border-radius: 6px;
    background-color: #4dd395
}

@media (min-width:1200px) {
    .page-with-title-banner__pulled-form-input-1 {
        max-width: 300px
    }
    .page-with-title-banner__pulled-form-input-2 {
        max-width: 270px
    }
    .page-with-title-banner__pulled-form-input-3 {
        max-width: 340px
    }
}

@media (min-width:1400px) {
    .container {
        max-width: 1170px;
        padding: 0
    }
}


